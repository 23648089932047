import {
  AUTHORIZE_CONFIRM_PATH,
  AUTHORIZE_PATH,
} from '../../../constants/paths';
import withOauthRouteHandling from '../../../decorators/auth/withOauthRouteHandling';
import OauthConfirmationLoader from './OauthConfirmationLoader';

export default withOauthRouteHandling(
  OauthConfirmationLoader,
  AUTHORIZE_PATH,
  AUTHORIZE_CONFIRM_PATH,
);
