import loadable from '@loadable/component';
import { Component } from 'react';
import createBundleLoader from './shared/createBundleLoader';

const LoadableComponent = loadable(
  () => import(/* webpackChunkName: "mobileWebEntry" */ './MobileWebEntry'),
  { fallback: createBundleLoader('MobileWebEntry') },
);

export default class MobileWebEntryLoader extends Component {
  render() {
    return <LoadableComponent {...this.props} />;
  }
}
