import loadable from '@loadable/component';
import createBundleLoader from '../../shared/createBundleLoader';

export default loadable(
  () =>
    import(/* webpackChunkName: "pairingSuccessPage" */ './PairingSuccessPage'),
  {
    fallback: createBundleLoader('PairingSuccessPage'),
  },
);
