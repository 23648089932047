import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { updateMWBypassCookie } from '../actions/app';
import { fetchMe } from '../actions/me';
import { mwLandingBypassCookie } from '../constants/cookies';
import { MW_LANDING_SHOW_AFTER_X_DAYS } from '../constants/experiments/mobileLanding';
import withAuth from '../decorators/auth/withAuth';
import withBreakpoints from '../decorators/withBreakpoints';
import withContextProviders from '../decorators/withContextProviders';
import withCurrentRouteCustomProps from '../decorators/withCurrentRouteCustomProps';
import withDesktopApp from '../decorators/withDesktopApp';
import withMobileJourney from '../decorators/withMobileJourney';
import useActions from '../hooks/useActions';
import useClientAnalytics from '../hooks/useClientAnalytics';
import useExperiment from '../hooks/useExperiment';
import useOneTrust from '../hooks/useOneTrust';
import { selectIsFord, selectUserAgent } from '../selectors/app';
import connectAsync from '../utils/connectAsync';
import { setCookie } from '../utils/cookie';
import AppRootLoader from './AppRootLoader';
import MobileWebEntryLoader from './MobileWebEntryLoader';
import { DiscordSplash, useDiscord } from './discord';
import withGuideItemProfile from './withGuideItemProfile';

const MOBILE_OVERLAY_EXPIRATION_DAYS = 1;

const AppConnectorComponent = memo((props) => {
  const {
    apiClient,
    onHistoryUpdate,
    location,
    match,
    route,
    routeProps,
    breakpoint,
    shouldShowMobileOverlay,
    history,
  } = props;
  const actions = useActions({ updateMWBypassCookie });
  const userAgent = useSelector(selectUserAgent);
  const mobileOverlayExpirationDaysOverride = useExperiment(
    MW_LANDING_SHOW_AFTER_X_DAYS,
  );
  const MobileWebLandingComponent = useMemo(
    () =>
      routeProps.guideContext.guideId
        ? withGuideItemProfile(MobileWebEntryLoader)
        : MobileWebEntryLoader,
    [routeProps.guideContext.guideId],
  );
  const { isDiscordReady, showDiscordSplashScreen, splashScreenType } =
    useDiscord(apiClient, history, routeProps);
  const contentProps = {
    breakpoint,
    route,
    routeProps,
    location,
    apiClient,
    history,
    isDiscordReady,
  };

  if (!routeProps.isFord) {
    // biome-ignore lint/correctness/useHookAtTopLevel: isFord is static, so we can break React's Rules of Hooks
    useOneTrust({ isMobileBlockerFlow: shouldShowMobileOverlay });
    // biome-ignore lint/correctness/useHookAtTopLevel: isFord is static, so we can break React's Rules of Hooks
    useClientAnalytics(routeProps, match, onHistoryUpdate);
  }

  if (showDiscordSplashScreen) {
    return <DiscordSplash splashScreenType={splashScreenType} />;
  }

  if (shouldShowMobileOverlay) {
    // TODO: this should be moved to MobileWebEntry
    const handleContinueClick = () => {
      const expDays =
        mobileOverlayExpirationDaysOverride || MOBILE_OVERLAY_EXPIRATION_DAYS;

      setCookie(mwLandingBypassCookie.name, expDays);
      actions.updateMWBypassCookie(true);
    };

    return (
      <MobileWebLandingComponent
        routeProps={routeProps}
        match={match}
        isLanding={routeProps.isLanding}
        location={location}
        userAgent={userAgent}
        onContinueClick={handleContinueClick}
      />
    );
  }

  return <AppRootLoader {...contentProps} />;
});

AppConnectorComponent.propTypes = {
  apiClient: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  routeProps: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  store: PropTypes.object,
  onHistoryUpdate: PropTypes.func,
  breakpoint: PropTypes.number.isRequired,
  shouldShowMobileOverlay: PropTypes.bool,
};

/**
 * We have two sets of HOCs, one for the base app and one for the full app.
 * Currently, we're optimizing the Ford app by using the base HOCs as it doesn't need all the features of the full app.
 */
const baseHOCs = [
  withBreakpoints, // IMPORTANT: This needs to render after withCurrentRouteCustomProps
  withCurrentRouteCustomProps,
  withAuth,
  connectAsync([fetchMe]),
  withContextProviders,
];

const allHOCs = [
  withMobileJourney,
  withBreakpoints, // IMPORTANT: This needs to render after withCurrentRouteCustomProps
  withCurrentRouteCustomProps,
  withAuth,
  connectAsync([fetchMe]),
  withDesktopApp,
  withContextProviders,
];

const applyHOCs = (Component, hocs) => {
  return flow(hocs)(Component);
};

const AppConnector = (props) => {
  const isFord = useSelector(selectIsFord);

  const hocs = useMemo(() => (isFord ? baseHOCs : allHOCs), [isFord]);

  const EnhancedComponent = useMemo(
    () => applyHOCs(AppConnectorComponent, hocs),
    [hocs],
  );

  return <EnhancedComponent {...props} />;
};

export default AppConnector;
