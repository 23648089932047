import flow from 'lodash/flow';

import connectAsync from '../utils/connectAsync';
import fetchAudioClip from '../utils/connectors/fetchAudioClip';
import fetchCategory from '../utils/connectors/fetchCategory';
import fetchProfile from '../utils/connectors/fetchProfile';
import fetchTopic from '../utils/connectors/fetchTopic';

import withAdScreenRequest from '../decorators/ads/withAdScreenRequest';
import getRadioContext from '../routes/utils/getRadioContext';
import { isCategory, isProfile } from '../utils/guideItemTypes';
import RadioRoute from './RadioRoute';

// determineAdScreen does not get called until the app is being rendered.
function determineAdScreen(props) {
  const { guideId } = getRadioContext(props) || {};

  if (!guideId) {
    return null;
  }

  if (isCategory(guideId)) {
    return 'browse';
  }

  if (isProfile(guideId)) {
    return 'profile';
  }

  return null;
}

export default flow(
  withAdScreenRequest(determineAdScreen),
  connectAsync([
    fetchCategory(getRadioContext),
    fetchProfile(getRadioContext),
    fetchTopic(getRadioContext),
    fetchAudioClip(getRadioContext),
  ]),
)(RadioRoute);
