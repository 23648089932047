import {
  DATA_REQUEST_AUTH_PATH,
  DATA_REQUEST_CONFIRM_PATH,
} from '../../../constants/paths';
import withOauthRouteHandling from '../../../decorators/auth/withOauthRouteHandling';
import DataRequestAuthLoader from './DataRequestAuthLoader';

export default withOauthRouteHandling(
  DataRequestAuthLoader,
  DATA_REQUEST_AUTH_PATH,
  DATA_REQUEST_CONFIRM_PATH,
);
