import {
  DATA_REQUEST_AUTH_PATH,
  DATA_REQUEST_CONFIRM_PATH,
} from '../../../constants/paths';
import withOauthRouteHandling from '../../../decorators/auth/withOauthRouteHandling';
import DataRequestConfirmationLoader from './DataRequestConfirmationLoader';

export default withOauthRouteHandling(
  DataRequestConfirmationLoader,
  DATA_REQUEST_AUTH_PATH,
  DATA_REQUEST_CONFIRM_PATH,
);
