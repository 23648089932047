import { useSelector } from 'react-redux';
import { selectIsFetching } from '../selectors/app';
import css from './loader.module.scss';

export const BaseLoader = () => (
  <div className={css.container} data-testid="loader">
    <div className={css.loader} />
  </div>
);

export default function Loader() {
  const isFetching = useSelector(selectIsFetching);

  return isFetching && <BaseLoader />;
}
