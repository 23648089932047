import url from 'url';
import { isType, matchGuideItemNumber } from '@tunein/web-common';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PODCASTS_BROWSE_PATH } from '../constants/paths';
import getRadioContext from '../routes/utils/getRadioContext';
import { selectAllProfiles } from '../selectors/profiles';
import getRootCategorySlug from '../utils/guideItem/getRootCategorySlug';
import getVanitySlug from '../utils/guideItem/getVanitySlug';
import { types as GuideItemTypes, isCategory } from '../utils/guideItemTypes';
import { isProfileInactive } from '../utils/profile';
import BrowseLoader from './browse/BrowseLoader';
import Error404 from './errors/404';
import ProfileLoader from './profile/ProfileLoader';

function determineRedirects(props) {
  const { location, match } = props;
  const { guideId } = getRadioContext(props);
  const isPodcast = get(match, 'params.podcastGuideIdentifier');
  if (isPodcast) {
    const isNumber = matchGuideItemNumber(guideId);
    if (!isNumber) {
      return url.format({
        pathname: PODCASTS_BROWSE_PATH,
        search: location.search,
      });
    }
  }

  const vanityRedirect = getVanitySlug(guideId);
  if (vanityRedirect) {
    return url.format({
      pathname: `/${vanityRedirect}/`,
      search: location.search,
    });
  }

  const rootCategoryRedirect = getRootCategorySlug(guideId);
  if (rootCategoryRedirect) {
    return url.format({
      pathname: `/${rootCategoryRedirect}/`,
      search: location.search,
    });
  }
  return null;
}

export default function RadioRoute(props) {
  const profiles = useSelector(selectAllProfiles);
  const { guideId } = getRadioContext(props) || {};
  const route = determineRedirects(props);

  useEffect(() => {
    if (route) {
      props.history.replace(route);
    }
  }, [route, props.history.replace]);

  if (route) {
    return null;
  }

  if (isCategory(guideId)) {
    return <BrowseLoader {...props} />;
  }

  if (
    isType(guideId, GuideItemTypes.station, GuideItemTypes.program) &&
    !isProfileInactive(guideId, profiles)
  ) {
    return <ProfileLoader {...props} />;
  }
  return <Error404 {...props} />;
}

RadioRoute.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
