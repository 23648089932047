import connectAsync from '../../../utils/connectAsync';
import fetchPartner from '../../../utils/connectors/fetchPartner';
import fetchProducts from '../../../utils/connectors/fetchProducts';
import { unauthenticateUser } from './../../../actions/auth';
import OauthAuthRoute from './OauthAuthRoute';

export default connectAsync([
  fetchPartner,
  ({ location }) => fetchProducts(location),
  unauthenticateUser,
])(OauthAuthRoute);
