import PropTypes from 'prop-types';
import { useContext } from 'react';
import { LocationAndLocalizationContext } from '../providers/LocationAndLocalizationProvider';

const baseStyles = {
  fontFamily: 'inherit',
};

function Text({ name, values, style, className }) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const formattedText = getLocalizedText(name, values);

  return (
    <span className={className} style={{ ...baseStyles, ...style }}>
      {formattedText}
    </span>
  );
}

Text.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Text;
