import url from 'url';
import PropTypes from 'prop-types';
import { Component } from 'react';

// eslint-disable-next-line react/prefer-stateless-function
class Redirect extends Component {
  static propTypes = {
    routeProps: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { routeProps, history } = this.props;

    if (routeProps.redirectPath) {
      const redirectUrl = url.format({
        pathname: routeProps.redirectPath,
        query: routeProps.query,
      });

      history.replace(redirectUrl);
    }
  }

  render() {
    return null;
  }
}

export default Redirect;
