import {
  AUTHORIZE_CONFIRM_PATH,
  AUTHORIZE_PATH,
} from '../../../constants/paths';
import withOauthRouteHandling from '../../../decorators/auth/withOauthRouteHandling';
import OauthAuthPageLoader from './OauthAuthPageLoader';

export default withOauthRouteHandling(
  OauthAuthPageLoader,
  AUTHORIZE_PATH,
  AUTHORIZE_CONFIRM_PATH,
);
