import { fetchMe } from '../../actions/me';
import { PAYMENT_FLOW } from '../../constants/subscription/flow';
import { selectIsSubscriptionEnabled } from '../../selectors/config';
import connectAsync from '../../utils/connectAsync';
import fetchProducts from '../../utils/connectors/fetchProducts';
import parse from '../../utils/queryString/parse';
import isCouponFlow from '../../utils/subscription/isCouponFlow';
import { isDiscountEnabled } from '../utils/subscriptionProductDetails';
import AuthPageRoute from './AuthPageRoute';

// exported for testing purposes
export function fetchProductsIfNeeded({ store, location }) {
  const state = store.getState();
  const query = parse(location?.search || '');
  const isPaymentFlow = !!query[PAYMENT_FLOW];
  const isDiscounted = isDiscountEnabled(location) || isCouponFlow(location);

  if (
    selectIsSubscriptionEnabled(state) &&
    isPaymentFlow &&
    (query.sku || isDiscounted)
  ) {
    return fetchProducts(location);
  }
  return null;
}

export default connectAsync([fetchMe, fetchProductsIfNeeded])(AuthPageRoute);
