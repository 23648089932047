import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { BaseLoader } from '../../components/Loader';
import { ACCOUNT_PATH } from '../../constants/paths';
import { selectIsAuthenticated } from '../../selectors/auth';
import AuthPageLoader from './AuthPageLoader';

class ResetPasswordRoute extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const routeTo = this.getRouteRedirect();
    if (routeTo) {
      props.history.replace(routeTo);
    }
    this.state = { routeTo };
  }

  getRouteRedirect() {
    const { auth } = this.props;

    return selectIsAuthenticated({ auth }) ? ACCOUNT_PATH : null;
  }

  render() {
    return this.state.routeTo ? (
      <BaseLoader />
    ) : (
      <AuthPageLoader {...this.props} />
    );
  }
}

export function mapStateToProps(state) {
  const { auth } = state;
  return { auth };
}

export default connect(mapStateToProps)(ResetPasswordRoute);
